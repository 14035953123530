import useVH from "react-viewport-height";
import { useLocation, useNavigate } from "react-router-dom";
import { useEffect, useRef, useState } from "react";
import { motion } from "framer-motion";
import useTimeout from "../../hooks/useTimeout";
import Sound from "../../components/Sound";
import MotionDiv from "../../components/motion/MotionDiv";
import Button from "../../components/UI/buttons/Button";

import stars from "../../assets/img/00_general/stars.png";
import stars2 from "../../assets/img/00_general/stars2.png";
import bill1 from "../../assets/img/04_verify/verify_code03-10.png";
import bill2 from "../../assets/img/04_verify/verify_code57-02.png";
import logos from "../../assets/img/04_verify/verify_logos.svg";

import verify from "../../assets/sounds/verify.mp3";
import bill1Src from "../../assets/sounds/success_10.mp3";
import bill2Src from "../../assets/sounds/success_02.mp3";

import learn_bill1 from "../../assets/files/learnmore_10-komprimiert.pdf";
import learn_bill2 from "../../assets/files/learnmore_02-komprimiert.pdf";

const Success = () => {
  useVH();
  let navigate = useNavigate();

  let location = useLocation();
  const { bill } = location.state as any;

  const verifySound = useRef(new Sound(verify));
  const bill1Sound = useRef(new Sound(bill1Src));
  const bill2Sound = useRef(new Sound(bill2Src));
  const [src, setSrc] = useState("");
  const [name, setName] = useState("");
  const [more, setMore] = useState("");

  useEffect(() => {
    if (!bill) return;

    if (bill === "bill1") {
      setSrc(bill1);
      setName("010");
      setMore(learn_bill1);
    } else {
      setSrc(bill2);
      setName("02");
      setMore(learn_bill2);
    }

    return () => {
      verifySound.current.sound.pause();
      bill1Sound.current.sound.pause();
      bill2Sound.current.sound.pause();
      verifySound.current.sound.currentTime = 0;
      bill1Sound.current.sound.currentTime = 0;
      bill2Sound.current.sound.currentTime = 0;
    };
  }, []);

  useTimeout(() => {
    console.log("try playing");
    verifySound.current.play();
  }, 500);

  useTimeout(() => {
    console.log("try playing");
    if (bill === "bill1") {
      console.log("playing bill1");
      bill1Sound.current.play();
    } else {
      console.log("playing bill2");
      bill2Sound.current.play();
    }
  }, 2000);

  return (
    <MotionDiv>
      <div className="grid grid-cols-1">
        {/* background stars */}
        <div className="col-start-1 row-start-1 bg-repeat bg-contain animate-shimmer" style={{ backgroundImage: `url(${stars})` }}></div>
        <div className="col-start-1 row-start-1 bg-repeat bg-contain animate-shimmer2" style={{ backgroundImage: `url(${stars2})` }}></div>

        {/* content */}
        <div className="z-10 flex flex-col items-center justify-between col-start-1 row-start-1 px-8 pt-16 pb-8 text-white minHeight100vh">
          <div className="text-2xl text-center">
            <p>Mission accomplished!</p>
            <p className="pt-4">{name} Banknote has been authenticated.</p>
          </div>

          <motion.div
            initial={{ opacity: 0, scale: 0.8 }}
            animate={{ opacity: 1, scale: [0.8, 1.2, 1] }}
            transition={{
              type: "spring",
              stiffness: 260,
              damping: 90,
              delay: 0.7,
            }}
          >
            <img src={src} alt="bill" className="mx-auto mt-6 mb-6 h-72" />
          </motion.div>

          <div className="grid justify-center grid-cols-1 gap-5">
            <div className="w-full text-center">
              <a
                href={more}
                className="inline-flex items-center justify-center w-56 px-3 py-3 text-center rounded-full text-kb-red bg-light-blue"
              >
                <div className="w-full text-2xl">Learn more</div>
              </a>
            </div>

            <Button
              // reset state to empty, otherwise it will throw an error
              onClick={() => navigate("/", { state: {} })}
              title="Start Over"
              className="w-56 mx-auto"
            />
          </div>

          <img src={logos} alt="Logos: Koenig & Bauer" className="h-3 mx-auto mt-10" />
        </div>
      </div>
    </MotionDiv>
  );
};

export default Success;
