import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import useVH from "react-viewport-height";
import { motion } from "framer-motion";
import useTimeout from "../../hooks/useTimeout";
import MotionDiv from "../../components/motion/MotionDiv";
import Button from "../../components/UI/buttons/Button";
import Sound from "../../components/Sound";

import stars from "../../assets/img/00_general/stars.png";
import stars2 from "../../assets/img/00_general/stars2.png";
// FIXME circle
import circle from "../../assets/img/04_verify/verify_countdown-circle.svg";
import step1 from "../../assets/img/04_verify/verify_mission-failed_icon-01.png";
import step2 from "../../assets/img/04_verify/verify_mission-failed_icon-02.png";
import logos from "../../assets/img/04_verify/verify_logos.svg";

import failure from "../../assets/sounds/failure.mp3";

const Failure = () => {
  useVH();
  let navigate = useNavigate();

  const [failureSound] = useState(new Sound(failure));

  const staggerFadeIn = {
    hidden: { opacity: 0 },
    show: {
      opacity: 1,
      transition: {
        duration: 1,
        delayChildren: 0.5,
        staggerChildren: 1,
      },
    },
  };

  useTimeout(() => {
    console.log("try playing");
    failureSound.play();
  }, 1000);

  useEffect(() => {
    return () => {
      failureSound.sound.pause();
      failureSound.sound.currentTime = 0;
    };
  }, []);

  return (
    <MotionDiv>
      <div className="grid grid-cols-1">
        {/* background stars */}
        <div className="col-start-1 row-start-1 bg-repeat bg-contain animate-shimmer" style={{ backgroundImage: `url(${stars})` }}></div>
        <div className="col-start-1 row-start-1 bg-repeat bg-contain animate-shimmer2" style={{ backgroundImage: `url(${stars2})` }}></div>

        {/* content */}
        <div className="z-10 flex flex-col items-center justify-between col-start-1 row-start-1 pt-10 pb-8 text-center text-white px-14 minHeight100vh">
          <div className="text-xl">
            <p>Sorry!</p>
            <p className="pt-2">Your mission was unsuccessful.</p>
          </div>

          <motion.div variants={staggerFadeIn} initial="hidden" animate="show">
            <motion.div variants={staggerFadeIn}>
              <div className="relative mt-8">
                <img src={step1} alt="" className="h-32 mx-auto" />
                <div
                  className="absolute top-0 p-2 text-3xl text-white bg-center bg-contain border-white left-2 tabular-nums font-arial"
                  style={{ backgroundImage: `url(${circle})` }}
                >
                  <div className="flex items-center justify-center w-10 h-10">1</div>
                </div>
              </div>
              <p className="mt-4">Place banknote on screen and hold the banknote with your thumb.</p>
            </motion.div>
            <motion.div variants={staggerFadeIn}>
              <div className="relative mt-10">
                <img src={step2} alt="" className="h-32 mx-auto" />
                <div
                  className="absolute top-0 p-2 text-3xl text-white bg-center bg-contain border-white left-2 tabular-nums font-arial"
                  style={{ backgroundImage: `url(${circle})` }}
                >
                  <div className="flex items-center justify-center w-10 h-10">2</div>
                </div>
              </div>
              <p className="mt-4">Swipe over the marked stripe towards the coordinate.</p>
            </motion.div>
          </motion.div>

          <div className="mt-8">
            <Button onClick={() => navigate("/")} title="Start Over" className="w-56 mx-auto" />
          </div>

          <img src={logos} alt="Logos: Koenig & Bauer" className="h-3 mx-auto mt-8" />
        </div>
      </div>
    </MotionDiv>
  );
};

export default Failure;
