import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { AnimatePresence, motion, useDragControls } from "framer-motion";
import { useMotionTimeline } from "motion-hooks";
import { RootState } from "../../../state/store";
import { ConnectivityResponse, UsabilityResponse } from "@prismadelabs/prismaid";
import { getScaleFactor, getScaleFactorFromLocalStorage } from "../../../helper/scale";
import useTimeout from "../../../hooks/useTimeout";
import { useAppDispatch, useAppSelector } from "../../../state/hooks";
import { setConnectivityStatus, setProgress, setScaleFactor } from "../../../state/slices/swipe";
import SDKSingleton from "../../../SDK";
import Sound from "../../../components/Sound";
import Spinner from "../../../components/UI/Spinner";
import ScaledImage from "../../../components/UI/images/ScaledImage";
import ScaledImageUnpositioned from "../../../components/UI/images/ScaledImageUnpositioned";
import DeviceNotSupportedModal from "./modals/DeviceNotSupportedModal";
import BrowserNotSupportedModal from "./modals/BrowserNotSupportedModal";
import ShouldAddToHomeScreenModal from "./modals/ShouldAddToHomeScreenModal";
import TouchSensitivityModal from "./modals/sensitivity/TouchSensitivityModal";
import GloveModeModal from "./modals/sensitivity/GloveModeModal";
import PointerSpeedModal from "./modals/sensitivity/PointerSpeedModal";

import banknote_placement from "../../../assets/img/03_scan/scan_bg-banknote-placement.png";
import banknote1 from "../../../assets/img/03_scan/scan_code03-10.png";
import banknote2 from "../../../assets/img/03_scan/scan_code57-02.png";

import endpoint from "../../../assets/img/03_scan/scan_endpoint.png";
import swipeArrow from "../../../assets/img/03_scan/scan_swipe-arrow.png";

import landing from "../../../assets/img/03_scan/scan_sputnik-landing.png";

import hand from "../../../assets/img/03_scan/scan_hand.png";
import thumb from "../../../assets/img/03_scan/scan_thumb.png";

import flying from "../../../assets/sounds/flying.mp3";
import success from "../../../assets/sounds/success.mp3";
import error from "../../../assets/sounds/error.mp3";
import satellite from "../../../assets/img/03_scan/scan_satellite.png";
import cloud from "../../../assets/img/03_scan/scan_cloud.png";

interface Props {}

// component
const FunctionalSwipeField = (props: Props) => {
  const dispatch = useAppDispatch();
  let navigate = useNavigate();

  const dragControls = useDragControls();

  const scaleFactor = useAppSelector((state: RootState) => state.swipe.scaleFactor);

  const [showModal_TouchSensitivity, setShowModal_TouchSensitivity] = useState(false);
  const [showModal_GloveMode, setShowModal_GloveMode] = useState(false);
  const [showModal_pointerspeed, setShowModal_pointerspeed] = useState(false);
  const [showModal_ShouldAddToHomeScreen, setShowModal_ShouldAddToHomeScreen] = useState(false);
  const [showModal_browserNotSupported, setShowModal_browserNotSupported] = useState(false);
  const [showModal_deviceNotSupported, setShowModal_deviceNotSupported] = useState(false);

  const successSound = useRef(new Sound(success));
  const errorSound = useRef(new Sound(error));
  const flyingSound = useRef(new Sound(flying));

  const [errorCount, setErrorCount] = useState(0);

  const [shouldPlayStartAnimation, setShouldPlayStartAnimation] = useState(true);
  const [shouldPlaySwipeAnimation, setShouldPlaySwipeAnimation] = useState(true);

  const [flashRedAlert, setFlashRedAlert] = useState(false);

  const [sdk] = useState(SDKSingleton.getInstance().sdk);

  // configure sdk
  useEffect(() => {
    flyingSound.current.sound.loop = true;

    // FIXME remove!
    // iPhone 12Pro
    // var scale = getScaleFactor(460, 3);
    // dispatch(setScaleFactor(scale));

    sdk.resume();

    let initialisationSubject = sdk.getInitialisationSubject().subscribe((response) => {
      console.log("*) initialisationResponse", response);

      if (response.ppi) {
        var scale = getScaleFactor(response.ppi, response.devicePixelRatio);

        if (!Number.isNaN(scale)) {
          dispatch(setScaleFactor(scale));
        }
      } else {
        setShowModal_deviceNotSupported(true);
        return;
      }

      if (response.deviceSupport?.requirements?.includes("touchsensitivity")) {
        setShowModal_TouchSensitivity(true);
        return;
      }

      if (response.deviceSupport?.requirements?.includes("glovemode")) {
        setShowModal_GloveMode(true);
        return;
      }

      if (response.deviceSupport?.requirements?.includes("pointerspeed")) {
        setShowModal_pointerspeed(true);
        return;
      }
    });

    const usabilitySubject = sdk.getUsabilitySubject().subscribe((response: UsabilityResponse) => {
      console.log("*) usabilityResponse", response);
      if (response.event === "device_not_supported") {
        setShowModal_deviceNotSupported(true);
        return;
      }
      if (response.event === "browser_not_supported") {
        setShowModal_browserNotSupported(true);
        return;
      }
      if (response.event === "display_too_small_displacement") {
        setShowModal_deviceNotSupported(true);
        return;
      }
      if (response.event === "display_small_should_add_to_home") {
        setShowModal_ShouldAddToHomeScreen(true);
        return;
      }
    });

    const detectionSuccessSubject = sdk.getDetectionSuccessSubject().subscribe((response) => {
      console.log("*) detection success:", response.description());

      if (response.codeId === "code3") {
        handleSwipeSuccess("bill1");
      } else if (response.codeId === "code57") {
        handleSwipeSuccess("bill2");
      } else {
        // bad code or swipeError
        clickErrorButton();
      }
    });

    const detectionErrorSubject = sdk.getDetectionErrorSubject().subscribe((response) => {
      console.log("*) detection error:", response.description());
      response.hints.forEach((hint) => {
        console.log("*) hint:", hint.description());
      });

      clickErrorButton();
    });

    const interactionSubject = sdk.getInteractionSubject().subscribe((response) => {
      console.log("*) interaction event:", response.event, response.activeSignals);

      switch (response.event) {
        case "started":
          let btnAll = document.getElementById("stopAnimationsButton") as HTMLButtonElement;
          btnAll.click();
          break;
        case "changed":
          if (response.activeSignals > 1) {
            flyingSound.current.sound.play();
          } else {
            flyingSound.current.sound.pause();
            flyingSound.current.sound.currentTime = 0;
          }
          break;
        case "complete":
          let btnRestart = document.getElementById("restartAnimationsButton") as HTMLButtonElement;
          btnRestart.click();
          dispatch(setProgress(0));
          break;
        default:
          break;
      }
    });

    const progressSubject = sdk.getProgressSubject().subscribe((response) => {
      console.log("*) progress:", response.progress, response.lastSwipePoint);
      dispatch(setProgress(response.progress));
    });

    const connectivitySubject = sdk.getConnectivitySubject().subscribe((response: ConnectivityResponse) => {
      console.log("*) connectivity response:", response.status);

      if (response.status === null) return;

      dispatch(setConnectivityStatus(response.status));
    });

    const screen = document.querySelector("#swipeScreen");
    if (screen) {
      sdk.attachToElement(screen);
    }

    return () => {
      initialisationSubject.unsubscribe();
      usabilitySubject.unsubscribe();
      progressSubject.unsubscribe();
      connectivitySubject.unsubscribe();
      detectionSuccessSubject.unsubscribe();
      detectionErrorSubject.unsubscribe();
      interactionSubject.unsubscribe();

      flyingSound.current.sound.pause();
    };
  }, []);

  useTimeout(
    () => {
      playStartAnimation();
    },
    shouldPlayStartAnimation ? 1000 : undefined
  );

  useTimeout(() => {
    if (!scaleFactor) {
      let storageFactor = getScaleFactorFromLocalStorage();
      if (storageFactor) {
        dispatch(setScaleFactor(storageFactor));
      }
    }
  }, 3000);

  useTimeout(
    () => {
      playSwipeAnimation();
    },
    shouldPlaySwipeAnimation ? 3000 : undefined
  );

  const handleSwipeSuccess = (bill: string) => {
    sdk.pause();
    successSound.current.play();
    stopAnimations();
    playSuccessAnimation();

    setTimeout(() => {
      navigate("/success", { state: { bill: bill } });
    }, 2000);

    setTimeout(() => {
      dispatch(setProgress(0));
    }, 3000);
  };

  // FIXME selector is not updating when called from subscription
  // works fine when using with button onClick
  // temporary workaround: use hidden button
  const clickErrorButton = () => {
    let btn = document.getElementById("errorButton") as HTMLButtonElement;
    btn.click();
  };
  const handleSwipeError = () => {
    errorSound.current.sound.currentTime = 0;

    if (errorCount >= 4) {
      errorSound.current.play();
      navigate("/failure", { replace: true });
      setTimeout(() => {
        dispatch(setProgress(0));
      }, 1000);
    } else {
      setErrorCount(errorCount + 1);
      showRedAlert();
    }
  };

  const showRedAlert = () => {
    setFlashRedAlert(true);
  };

  const { play: playStartAnimation, timelineInstance: startAnimation } = useMotionTimeline([
    ["#landing", { opacity: 1 }, { duration: 0.7 }],
    ["#satellite", { opacity: 1 }, { duration: 0.7, at: "<" }],
    ["#cloud", { opacity: 1 }, { duration: 0.7, at: "<" }],

    ["#swipeArrow", { opacity: 1 }, { duration: 1 }],
    ["#endpoint", { opacity: 1 }, { duration: 1, at: "<" }],
  ]);

  const { play: playSwipeAnimation, timelineInstance: swipeAnimation } = useMotionTimeline(
    [
      ["#banknote1", { opacity: [null, 1, 0, 1, 0] }, { duration: 4 }],
      ["#banknote2", { opacity: [null, 0, 1, 0, 1] }, { duration: 4, at: "<" }],
      ["#satelliteShell", { top: [null, -10, 0, 10, 0, -10, 0] }, { duration: 4, at: "<", easing: "linear" }],
      ["#banknote2", { opacity: 0 }, { duration: 1, endDelay: 3 }], // endDelay to finish with the one before

      ["#thumb", { opacity: 1 }, { duration: 0.2 }],
      ["#thumbShell", { left: ["-50%", 0], top: ["30%", 0], scale: [1.3, 1] }, { duration: 2, at: "<" }],

      ["#hand", { opacity: 1 }, { duration: 0.2 }],
      ["#handShell", { left: ["100%", 0], top: ["-30%", 0], scale: [1.3, 1] }, { duration: 2, at: "<" }],

      ["#handShell", { top: [0, `${-1320 * scaleFactor}px`] }, { duration: 2, at: "+0.5" }],

      ["#handShell", { left: [null, "100%"], top: [null, "-30%"] }, { duration: 2, at: "+0.5" }],
      ["#thumbShell", { left: [null, "-100%"], top: [null, "30%"] }, { duration: 2, at: "+0.5" }],
    ],
    { repeat: 2 }
  );

  const { play: playSuccessAnimation } = useMotionTimeline([
    ["#satelliteContainer", { top: [null, "-120%"] }, { duration: 0.3, easing: "linear" }],
    ["#cloud", { opacity: [null, 1] }, { duration: 1, at: "<", easing: "linear" }],
    ["#text", { opacity: 1 }, { duration: 1, at: "<", endDelay: 2 }],
  ]);

  const stopAnimations = () => {
    setShouldPlayStartAnimation(false);
    setShouldPlaySwipeAnimation(false);
    startAnimation?.finish();
    swipeAnimation?.finish();
  };

  const restartAnimations = () => {
    setShouldPlayStartAnimation(true);
    setShouldPlaySwipeAnimation(true);
  };

  const closeTouchModalAndRestartAnimation = () => {
    setShowModal_TouchSensitivity(false);
    setShowModal_pointerspeed(false);
    setShowModal_GloveMode(false);
    setShouldPlayStartAnimation(false);
    setShouldPlaySwipeAnimation(false);
    restartAnimations();
  };

  return (
    <>
      <div id="swipeScreen" className="absolute top-0 left-0 w-screen overflow-hidden height100vh bg-opacity-30 bg-space-blue">
        {!scaleFactor && !(scaleFactor > 0) ? (
          <>
            <div className="absolute inset-0 flex items-center justify-center text-white bg-space-blue bg-opacity-60">
              <Spinner />
            </div>
          </>
        ) : (
          <>
            <ScaledImage
              src={banknote_placement}
              id="placement"
              alt="placement"
              horizontalAlign="center"
              verticalAlign="center"
              scaleFactor={scaleFactor}
            />

            {flashRedAlert && (
              <AnimatePresence>
                <motion.div
                  className="absolute inset-0 h-screen bg-kb-red touch-none"
                  initial={{ opacity: 0 }}
                  animate={{ opacity: [100, 0] }}
                  transition={{
                    duration: 0.2,
                    ease: "linear",
                    repeat: 1,
                    repeatDelay: 0.2,
                  }}
                  onAnimationComplete={() => {
                    setFlashRedAlert(false);
                  }}
                />
              </AnimatePresence>
            )}

            <ScaledImage
              src={banknote1}
              id="banknote1"
              alt="banknote1"
              horizontalAlign="center"
              verticalAlign="center"
              horizontalOffset={42}
              verticalOffset={12}
              scaleFactor={scaleFactor}
              opacity={0}
            />
            <ScaledImage
              src={banknote2}
              id="banknote2"
              alt="banknote2"
              horizontalAlign="center"
              verticalAlign="center"
              horizontalOffset={-46}
              verticalOffset={50}
              scaleFactor={scaleFactor}
              opacity={0}
            />
            <ScaledImage
              src={landing}
              id="landing"
              alt="landing"
              horizontalAlign="center"
              verticalAlign="center"
              horizontalOffset={1}
              scaleFactor={scaleFactor}
              opacity={0}
            />
            <ScaledImage
              src={swipeArrow}
              id="swipeArrow"
              alt="swipeArrow"
              horizontalAlign="center"
              verticalAlign="center"
              verticalOffset={25}
              scaleFactor={scaleFactor}
              opacity={0}
            />
            <ScaledImage
              src={endpoint}
              id="endpoint"
              alt="endpoint"
              horizontalAlign="center"
              verticalAlign="center"
              verticalOffset={-630}
              scaleFactor={scaleFactor}
              opacity={0}
            />

            <motion.div
              id="satelliteContainer"
              className="absolute top-0 z-30 mx-auto"
              style={{ top: "50%", left: "50%" }}
              drag="y"
              dragConstraints={{ top: 0, right: 0, bottom: 0, left: 0 }}
              dragElastic={1}
              dragControls={dragControls}
              dragListener={false}
            >
              <div
                style={{
                  // add offset for positioning
                  transform: `translateX(calc(-50% + ${-3 * (scaleFactor || 1)}px)) translateY(${380 * (scaleFactor || 1)}px)`,
                }}
              >
                <div
                  id="satelliteShell"
                  className={`flex flex-col items-center px-5`}
                  style={{
                    paddingTop: 100 * scaleFactor,
                  }}
                  onPointerDown={(e) => {
                    dragControls.start(e);
                  }}
                >
                  <div className="animate-wiggle">
                    <ScaledImageUnpositioned
                      className="-mb-10"
                      src={satellite}
                      id="satellite"
                      alt="satellite"
                      scaleFactor={scaleFactor}
                      opacity={0}
                    />
                  </div>
                  <ScaledImageUnpositioned className="-pt-10" src={cloud} id="cloud" alt="cloud" scaleFactor={scaleFactor} opacity={0} />
                </div>
              </div>
            </motion.div>

            <div className="absolute top-0 left-0 flex items-end justify-center w-screen text-6xl pointer-events-none touch-none height100vh text-kb-blue">
              <div
                id="text"
                className="bottom-0 z-30 rotate-90 -translate-x-6 -translate-y-40 pointer-events-none touch-none"
                style={{ opacity: 0 }}
              >
                we're on it.
              </div>
            </div>

            <div className="absolute left-0 z-30 w-screen pointer-events-none height100vh touch-none" id="thumbShell" style={{ top: 0 }}>
              <ScaledImage
                src={thumb}
                id="thumb"
                alt="thumb"
                horizontalAlign="center"
                verticalAlign="center"
                horizontalOffset={-400}
                verticalOffset={350}
                scaleFactor={scaleFactor}
                opacity={0}
              />
            </div>
            <div className="absolute left-0 z-30 w-screen pointer-events-none height100vh touch-none" id="handShell" style={{ top: 0 }}>
              <ScaledImage
                src={hand}
                id="hand"
                alt="hand"
                horizontalAlign="center"
                verticalAlign="center"
                horizontalOffset={230}
                verticalOffset={900}
                scaleFactor={scaleFactor}
                opacity={0}
              />
            </div>
          </>
        )}
      </div>

      <TouchSensitivityModal isOpen={showModal_TouchSensitivity} setIsOpen={closeTouchModalAndRestartAnimation} />
      <GloveModeModal isOpen={showModal_GloveMode} setIsOpen={closeTouchModalAndRestartAnimation} />
      <PointerSpeedModal isOpen={showModal_pointerspeed} setIsOpen={closeTouchModalAndRestartAnimation} />

      <ShouldAddToHomeScreenModal isOpen={showModal_ShouldAddToHomeScreen} setIsOpen={setShowModal_ShouldAddToHomeScreen} />
      <BrowserNotSupportedModal isOpen={showModal_browserNotSupported} setIsOpen={setShowModal_browserNotSupported} />
      <DeviceNotSupportedModal isOpen={showModal_deviceNotSupported} setIsOpen={setShowModal_deviceNotSupported} />

      <div className="absolute z-10 space-x-2 bottom-2">
        <button onClick={() => handleSwipeError()} id="errorButton" className="hidden">
          handleSwipeError
        </button>
        <button
          onClick={() => {
            stopAnimations();
          }}
          id="stopAnimationsButton"
          className="hidden"
        >
          stopSwipeAnimation
        </button>
        <button
          onClick={() => {
            restartAnimations();
          }}
          id="restartAnimationsButton"
          className="hidden"
        >
          restartSwipeAnimation
        </button>
      </div>
    </>
  );
};

export default FunctionalSwipeField;
