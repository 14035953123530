import { useState } from "react";
import useInterval from "../../hooks/useInterval";
import useTimeout from "../../hooks/useTimeout";
import Button from "../UI/buttons/Button";
import Modal from "./Modal";

const WithSessionExpiredWarning = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [countdown, setCountdown] = useState(5);
  const [countdownTimer, setCountdownTimer] = useState(false);

  const handleReload = () => {
    window.location.reload();
  };

  useTimeout(() => {
    console.log("timeout");
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
    setIsOpen(true);
  }, 1000 * 60 * 58); // 58min in milliseconds

  useInterval(
    () => {
      setCountdown(countdown - 1);
      if (countdown === 1) {
        handleReload();
      }
    },
    isOpen ? 1000 : null
    // countdownTimer ? 1000 : null
  );

  return (
    <Modal isOpen={isOpen} setIsOpen={() => {}} title="Session expired">
      <div className="w-64">
        <p className="mt-4 text-lg">Reloading in {countdown}</p>
        <div className="mt-8">
          <Button
            title="Ok"
            onClick={() => {
              handleReload();
            }}
            className="w-full"
          />
        </div>
      </div>
    </Modal>
  );
};

export default WithSessionExpiredWarning;
