import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Lottie from "react-lottie-player";
import useVH from "react-viewport-height";
import { useAppDispatch } from "../../state/hooks";
import { setInvocationIsValid } from "../../state/slices/app";
import MotionDiv from "../../components/motion/MotionDiv";
import Button from "../../components/UI/buttons/Button";

import stars from "../../assets/img/00_general/stars.png";
import stars2 from "../../assets/img/00_general/stars2.png";
import marksLottie from "../../assets/img/01_start/11514-swipe-down-arrows-white.json";
import satellite from "../../assets/img/01_start/start_satellite.png";
import globe from "../../assets/img/01_start/start_globe_slice2.png";

const Start = () => {
  useVH();
  const dispatch = useAppDispatch();
  let navigate = useNavigate();

  useEffect(() => {
    dispatch(setInvocationIsValid());
  }, []);

  return (
    <MotionDiv>
      <div className="grid grid-cols-1">
        {/* background stars */}
        <div className="col-start-1 row-start-1 bg-repeat bg-contain animate-shimmer" style={{ backgroundImage: `url(${stars})` }}></div>
        <div className="col-start-1 row-start-1 bg-repeat bg-contain animate-shimmer2" style={{ backgroundImage: `url(${stars2})` }}></div>

        {/* content */}
        <div
          className="z-10 flex flex-col col-start-1 row-start-1 px-8 text-white bg-bottom bg-no-repeat bg-contain minHeight100vh"
          style={{ backgroundImage: `url(${globe})` }}
        >
          <div className="flex flex-col justify-between pt-14 minHeight100vh">
            <div className="px-4 max-w-[18rem]">
              <h1 className="text-5xl leading-snug font-kbheadline">
                Mission
                <br />
                Request:
              </h1>
              <p className="pt-10 text-3xl leading-snug font-extralight">
                You have been selected to authenticate a banknote from the Laika Series.
              </p>
              <p className="pt-8 text-3xl leading-snug">Are you ready?</p>
            </div>
            <Lottie loop animationData={marksLottie} play className="h-24" />
          </div>
          <img src={satellite} alt="satellite" className="w-64 h-64 mx-auto" />
          <div className="flex justify-center pb-10">
            <Button
              onClick={() => {
                window.scrollTo({
                  top: 0,
                  behavior: "smooth",
                });
                navigate("/tutorial");
              }}
              title="Accept"
              primary={false}
              className="w-56 uppercase shadow-lg"
            />
          </div>
        </div>
      </div>
    </MotionDiv>
  );
};

export default Start;
