import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { AnimatePresence, motion } from "framer-motion";
import Lottie from "react-lottie-player";
import useVH from "react-viewport-height";
import MotionDiv, { MotionDivVariant } from "../../components/motion/MotionDiv";
import BackButton from "../../components/UI/buttons/BackButton";
import Button from "../../components/UI/buttons/Button";

import stars from "../../assets/img/00_general/stars.png";
import stars2 from "../../assets/img/00_general/stars2.png";
import circle from "../../assets/img/02_onboarding/ob_circle.svg";
import image1 from "../../assets/img/02_onboarding/ob_K&B_apply.json";
import image2 from "../../assets/img/02_onboarding/ob_K&B_swipe.json";

const Tutorial = () => {
  useVH();
  let navigate = useNavigate();
  const [nextSlide, setNextSlide] = useState<boolean>();

  const dotVariants = {
    full: { width: 18, height: 18, backgroundColor: "#FF0000FF" },
    empty: { width: 12, height: 12, backgroundColor: "#FF000000" },
  };

  return (
    <MotionDiv>
      <div className="grid w-screen grid-cols-1 overflow-hidden touch-none">
        {/* background stars */}
        <div className="col-start-1 row-start-1 bg-repeat bg-contain animate-shimmer" style={{ backgroundImage: `url(${stars})` }}></div>
        <div className="col-start-1 row-start-1 bg-repeat bg-contain animate-shimmer2" style={{ backgroundImage: `url(${stars2})` }}></div>

        {/* content */}
        <div className="z-10 col-start-1 row-start-1 px-8 pt-6 pb-10 text-white height100vh">
          <div className="flex flex-col items-center w-full h-full text-white bg-space-blue bg-opacity-90">
            <div className="absolute self-start top-2 left-2">
              <BackButton
                onClick={() => {
                  if (!nextSlide) {
                    navigate("/", { replace: true });
                  } else {
                    setNextSlide(nextSlide ? !nextSlide : true);
                  }
                }}
              />
            </div>
            <div className="mt-10">
              <div className="flex items-center justify-center w-screen">
                <div
                  className="relative p-3 text-4xl text-white bg-center bg-contain border-white font-arial"
                  style={{ backgroundImage: `url(${circle})` }}
                >
                  <div className="flex items-center justify-center w-10 h-10">
                    {!nextSlide && <MotionDiv key="slide1">1</MotionDiv>}
                    {nextSlide && <MotionDiv key="slide2">2</MotionDiv>}
                  </div>
                </div>

                <div className="ml-4 text-xl text-white w-52">
                  Instructions:
                  <br />
                  {!nextSlide && (
                    <MotionDiv key="slide1">
                      <span className="text-2xl">Placing Banknote</span>
                    </MotionDiv>
                  )}
                  {nextSlide && (
                    <MotionDiv key="slide2">
                      <span className="text-2xl">Swiping</span>
                    </MotionDiv>
                  )}
                </div>
              </div>
            </div>

            <div className="flex-grow w-screen min-h-0">
              <AnimatePresence exitBeforeEnter>
                {!nextSlide && (
                  <MotionDiv
                    initial={nextSlide != null ? MotionDivVariant.InitialOnLeft : MotionDivVariant.InitialVisible}
                    animate={MotionDivVariant.SlideInHorizontal}
                    exit={MotionDivVariant.SlideOutToLeft}
                    key="image1"
                    className="h-full"
                  >
                    <Lottie loop animationData={image1} play className="h-full" />
                  </MotionDiv>
                )}
                {nextSlide && (
                  <MotionDiv
                    initial={MotionDivVariant.InitialOnRight}
                    animate={MotionDivVariant.SlideInHorizontal}
                    exit={MotionDivVariant.SlideOutToRight}
                    key="image2"
                    className="w-screen h-full overflow-hidden"
                  >
                    <Lottie loop animationData={image2} play className="h-full" />
                  </MotionDiv>
                )}
              </AnimatePresence>
            </div>
            <div className="flex items-center my-4 space-x-3">
              <AnimatePresence>
                <motion.div
                  key="dot1"
                  className="border-2 rounded-full border-kb-red"
                  variants={dotVariants}
                  animate={nextSlide ? "empty" : "full"}
                  transition={{ type: "linear" }}
                ></motion.div>
                <motion.div
                  key="dot2"
                  className="border-2 rounded-full border-kb-red"
                  variants={dotVariants}
                  animate={!nextSlide ? "empty" : "full"}
                  transition={{ type: "linear" }}
                ></motion.div>
              </AnimatePresence>
            </div>
            <Button
              title={!nextSlide ? "Continue" : "Launch"}
              onClick={() => {
                if (!nextSlide) {
                  setNextSlide(nextSlide ? !nextSlide : true);
                } else {
                  navigate("/swipe", { replace: true });
                }
              }}
              className="w-56 uppercase"
              primary={false}
            />
          </div>
        </div>
      </div>
    </MotionDiv>
  );
};

export default Tutorial;
